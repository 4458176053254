
.MD_logo_transparent{
    /* margin-top: 15%; */
    /* margin-right: 15%; */
}


.border-buttons-0 .btn{
    border-radius: 0%;
}




@media only screen and (min-width: 600px) {

    .carousel-image{
        width: 100%; 
        height: auto; 
        max-height: 500px; 
        object-fit: contain;
    }

    #our_values_fourth{
        color: white;
        height: 100vh;
    }

}

@media only screen and (max-width: 600px) {

    .carousel-image{
        width: 50%; 
        height: auto; 
        /* max-height: 500px;  */
        object-fit: contain;
    }

    #our_values_fourth{
        color: white;
        height: 75vh;
    }
    
}