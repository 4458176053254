
@media only screen and (min-width: 600px) {

    .VTO_Main{
            position: absolute;
            top: 0;
            left: 0;
            width: 50%;
            height: 100%;
            background-color: rgba(0, 0, 0, 0.7);
            z-index:  2;  
    }

    .millionDreams_text h1{
        font-size: 6cap;
        font-weight: 600;
    }
    .millionDreams_text p{
        /* font-size: larger; */
        font-size: 2cap;
        text-align: end;
    }

    .MD_logo_transparent{
        /* margin-top: 15%; */
        /* margin-right: 15%; */
        width:35%;
    }
} 

@media only screen and (max-width: 600px) {

    .VTO_Main{
        position: absolute;
        top: 0;
        left: 0;
        width: 50%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.7);
        z-index:  2;  
}

    .millionDreams_text h1{
        /* font-size: larger; */
        margin-top: 1px;
        font-size: 2cap;
        font-weight: 600;
        /* flex:content; */
        /* flex-wrap: row; */
        
        
    }

    .millionDreams_text .dis{
        display:none;
        /* visibility:hidden; */
    }
    

    .millionDreams_text p{
        /* font-size: larger; */
        font-size: 1cap;
        text-align: end;
    }

    .MD_logo_transparent{
        margin-top: 30%;
        /* margin-right: 15%; */
        width:75%;
    }
} 
