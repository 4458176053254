.footer {
    /* text-align: center;
    position:sticky;  */
    position: relative;
    /* font-size: 1px; */
    /* Figure out why the Banner stays where you need depending on this */
    /* top: 100px; */
    background-color: rgb(33, 37, 41); 
    z-index: 1000; /* Ensure it's above other content */
}

.text_brand_footer{
    font-size: 20px;
}