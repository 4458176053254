
.MD_logo_transparent{
    /* margin-top: 15%; */
    /* margin-right: 15%; */
}

.border-buttons-0 .btn{
    border-radius: 0%;
}




@media only screen and (min-width: 600px) {
    .title_values{
        font-size: 6cap;
        font-weight: 600;
    }

    #quoted_text{
        /* margin-top: 100px; */
        font-size: 6cap;
    }

    #our_values{
        color: white;
        height: 100vh;
    }
    
    .button_width{
        width: 150px;
    }
} 

@media only screen and (max-width: 600px) {
    .title_values{
        font-size: 3cap;
        font-weight: 600;
    }

    #quoted_text{
        /* margin-top: 100px; */
        font-size: 2cap;
        margin-top: 10%;
    }

    #our_values{
        color: white;
        height: 75vh;
    }
    .button_width{
        width: 150px;
    }
} 
