
hr{
    width: 100%;
    border-style: solid;
    border-color: white;
    border-color: aliceblue;
}



.card-img{
    display: block;
    height: 100%; 
    width: 100%;
    object-fit: cover;
}

@keyframes firstThird {

    0%   {top: 100px; opacity: 0;}
    100%  {top: 0px; opacity: 1;}
    
  }
  


.secondThird_container{

    position: relative;
    bottom: 100px;
    color: white;
    animation-name: secondThird;
    animation-duration: 2s;
    bottom: 0px;

  }



@media (min-width: 600px) {

    #bg_video {
        width: 100%;
        height: 100vh;
        /* position: fixed; */
        /* HERE IS THE ONE I NEED TO CHANGE */
        position: relative;
        color: white;
        /* background-position: center;
        background-repeat: no-repeat;
        background-size: cover; */
        pointer-events: none;
    }
  
    #bg_video video {
      width: 100%;
      height: 100vh;
      object-fit: cover;
  }

    .firstThird_container{

        margin-top: 0px;
        position: relative;
    
        top: 0px;
    
        animation-name: firstThird;
        animation-duration: 2s;
        top: 0px;

        /* border-width: 10%;
        border-left: 0.5px;
        border-right: 0.5px;
        border-top: 0px;
        border-bottom: 0px;
        border-color: rgba(255, 255, 255, 0.3);
        border-style: ridge; */


    
    }

    .ThirdThird_container{

        position: relative;
        left: 100px;
        
        animation-name: thirdThird;
        animation-duration: 2s;
        left: 0px;
        
        }

    @keyframes thirdThird {

        0%   {left: 100px; opacity: 0;}
        100%  {left: 0px; opacity: 1;}
        
        }
        

}

@media (max-width: 600px) {


    #bg_video {
        width: 100%;
        height: 75vh;
        /* position: fixed; */
        /* HERE IS THE ONE I NEED TO CHANGE */
        position: relative;
        color: white;
        /* background-position: center;
        background-repeat: no-repeat;
        background-size: cover; */
        pointer-events: none;
    }
  
    #bg_video video {
      width: 100%;
      height: 75vh;
      object-fit: cover;
  }

    .firstThird_container{

        margin-top: 0px;
        position: relative;
    
        top: 0px;
    
        animation-name: firstThird;
        animation-duration: 2s;
        top: 0px;
    
        border-width: 10%;
        border-top: 0.5px;
        border-bottom: 0.5px;
        border-left: 0px;
        border-right: 0px;
        border-color: rgba(255, 255, 255, 0.3);
        border-style: ridge;
    }

    .ThirdThird_container{

        position: relative;
        right: 100px;
        
        animation-name: thirdThird;
        animation-duration: 2s;
        right: 0px;
        
        }

    @keyframes thirdThird {

        0%   {right: 100px; opacity: 0;}
        100%  {right: 0px; opacity: 1;}
        
        }
        

}



.forthThird_container{

    position: relative;
    right: 100px;
    
    animation-name: forthThird_container;
    animation-duration: 2s;
    right: 0px;
    
    }
    
@keyframes forthThird_container {
    
    0%   {right: 100px; opacity: 0;}
    100%  {right: 0px; opacity: 1;}
    
    }

    .second_third{

        display: flex;
        flex-direction: row;
        justify-content: center;
        color: white;
    
    }
    
    .columns_grid {
        display: grid;
        grid-template-columns: auto ;
        gap: 0px 20px;
      }
    
      
    
      .first_education{
    
        /* background-image: url("https://uploads-ssl.webflow.com/5a57b3d9f98088000123eb4c/5eb9a067b0347957f67c9686_WebRes_IMG_05910-173.jpg"); */
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
        /* filter: blur(1px); */
    
      }
    
      .second_education{
    
        /* background-image: url("https://www.ucaldas.edu.co/portal/wp-content/uploads/2020/05/programas-especiales.png"); */
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
        /* filter: blur(1px); */
    
      }
    
      .third_education{
    
        /* background-image: url("https://certificadossena.net/wp-content/uploads/2022/10/carreras-tecnicas-y-tecnologicas-en-sena-manizales-1024x555.jpg"); */
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
        /* filter: blur(1px); */
    
      }